

























































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readUserProfile,
} from '@/store/main/getters';
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
} from '@/store/main/mutations';
import { dispatchUserLogOut } from '@/store/main/actions';

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/user');
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {

  public showMenu = false;
  public toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  get mobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return '-';
      }
    }
  }

  get getMail() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.email) {
        return userProfile.email;
      } else {
        return '-';
      }
    }
  }

  public async mounted() {
    const userProfile = readUserProfile(this.$store);
    // eslint-disable-next-line
    // (window as any).$crisp=[];
    // (window as any).$crisp.push(['set', 'session:data', ['email', userProfile?.email]]);
    // (window as any).$crisp.push(['set', 'session:data', ['name', userProfile?.full_name]]);
    // (window as any).$crisp.push(['set', 'user:nickname', [userProfile?.full_name]]);
    // // eslint-disable-next-line
    // (window as any).CRISP_WEBSITE_ID="22a956d8-4360-4ad7-b558-5fa7f9e1a53e";
    // const d = document;
    // const s = d.createElement('script');
    // s.src = 'https://client.crisp.chat/l.js';
    // s.async = true;
    // d.getElementsByTagName('head')[0].appendChild(s);
  }

  get userSelf() {
    return readUserProfile(this.$store);
  }

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store)
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store)
    );
  }

  public clickFAQ() {
    (window as any).$crisp.push(['do', 'helpdesk:search']);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
